.hero{
    width: 100%;
    min-height: 100vh;
    background: linear-gradient(rgba(8,0,58,0.7), rgba(8,0,58,0.7)),url('../../assets/hero.png');
    background-size: cover;
    background-position: center;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.hero-text{
    display: flex;

    max-width: 1050px;
}

.hero-right{
    text-align: end;
}

.hero-left, .hero-right{
    flex-basis: 50%;
}

.hero-text h1{
    font-size: 60px;
    font-width: 600;
}

.hero-text p{
    max-width: 1050px;
    margin: 10px auto 20px;
    line-height: 1.4;
}