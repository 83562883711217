nav {
  width: 100%;
  color: #fff;
  padding: 4px 0;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 10;
}

.logo-container {
  display: flex;
  align-items: center;
}

.logo-container img{
    margin-right: 10px;
}

.logo-container p{
    font-size: 20px;
    font-weight: 600;
}

.logo {
  width: 50px;
}

nav ul li {
  display: inline-block;
  list-style: none;
  margin: 5px 20px;
  font-size: 16px;
}

.dark-nav {
  background: #212ea0;
  transition: 0.5s;
}
