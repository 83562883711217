* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background: #f5f5f5;
}

a {
  text-decoration: none;
  color: inherit;
  line-height: 1;
  cursor: pointer;
}

.container {
  padding: 5px 6%;
}

.btn {
  background: #fff;
  color: #212121;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 30px;
  cursor: pointer;
  border: 0;
  outline: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.btn img {
  width: 20px;
  margin-left: 10px;
}

.btn.dark-btn {
  background: #212ea0;
  color: #fff;
}
