.title {
  width: 100%;
  text-align: center;
  margin: 30px;
}

.recommended {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.recommended div {
  width: 21%;
  padding: 20px;
  text-align: center;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.recommended div h3 {
  text-align: center;
  margin-bottom: 20px;
}

.recommended div img {
  width: 100%;
}
