.blogs{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.blogs div{
    width: 30%;
    height: 300px;
    overflow: hidden;
    padding: 20px;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.blogs div h2{
    color: #212ea0;
    margin-bottom: 10px;
}

.blogs div p{

}